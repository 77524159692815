






















































import {
  defineComponent,
  PropType,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api";
import { ImageModifiers } from "@nuxt/image";
import { SfButton, SfImage } from "@storefront-ui/vue";

export default defineComponent({
  name: "HeroSection",
  components: {
    SfButton,
    SfImage,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    secondaryButtonText: {
      type: String,
      default: "",
    },
    primaryButtonText: {
      type: String,
      default: "",
    },
    imageHeight: {
      type: [Number, String],
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    imageTag: {
      type: String,
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: "",
    },
    primaryLink: {
      type: String,
      default: "",
    },
    secondaryLink: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },
  setup() {
    const { app } = useContext();
    const router = useRouter();
    const { isMobile } = app.$device;
    const goToDefaultCategory = async () => {
      if (isMobile) {
        //await router.push(app.localeRoute({ name: "default-category" }));
        await router.push("/mt/c/all-products.html?on_sale=1&page=0");
      }
    };

    return {
      goToDefaultCategory,
    };
  },
});
