





















































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useMeta,
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBanner, SfBannerGrid, SfModal, SfButton } from "@storefront-ui/vue";
import HeroSection from "~/components/HeroSection.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import { homeJsonLd } from "~/assets/jsonld/jsonld";
import {
  useConfig,
  useMagentoConfiguration,
  useNewsletter,
} from "~/composables";

import NewsLetterSubscriptionModal from "~/modules/hudson/components/subscriptionLetter/NewsLetter.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    SfModal,
    SfButton,
    NewsLetterSubscriptionModal,
    InstagramFeed: () =>
      import(/* webpackPrefetch: true */ "~/components/InstagramFeed.vue"),
    MobileStoreBanner: () =>
      import(/* webpackPrefetch: true */ "~/components/MobileStoreBanner.vue"),
    NewProducts: () =>
      import(/* webpackPrefetch: true */ "~/components/NewProducts.vue"),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  head: {},
  setup() {
    const { addTags } = useCache();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { selectedCurrency, selectedLocale, selectedStore } =
      useMagentoConfiguration();
    const { config } = useConfig();
    const { isDesktop, isMobile } = app.$device;

    const showNewsModel = ref(false);

    const hero = ref({
      title: app.i18n.t(""),
      subtitle: app.i18n.t("", { year }),
      primaryButtonText: app.i18n.t("Shop Women"),
      secondaryButtonText: app.i18n.t("Shop Men"),
      imageWidth: isDesktop ? 1240 : 328,
      imageHeight: isDesktop ? 586 : 328,
      imageSrc: isDesktop
        ? "https://cdn.hudson.com.mt/assets/hudson-store/banners/desktop/Home-Banner.webp"
        : "https://cdn.hudson.com.mt/assets/hudson-store/banners/mobile/Home-Banner.webp",
      imageConfig: {
        fit: isDesktop ? "cover" : "contain",
        format: "webp",
      },
      primaryLink: "/c/women.html?on_sale=1&page=0",
      secondaryLink: "/c/men.html?on_sale=1&page=0",
    });
    const banners = ref([
      {
        slot: "banner-A",
        subtitle: app.i18n.t("HOT BRAND"),
        title: app.i18n.t(""),
        description: app.i18n.t(""),
        buttonText: app.i18n.t("Shop now"),
        image: isDesktop
          ? "https://cdn.hudson.com.mt/assets/hudson-store/banners/desktop/Nike.webp"
          : "https://cdn.hudson.com.mt/assets/hudson-store/banners/mobile/Nike.webp",
        imageConfig: {
          fit: "cover",
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 840 : 343,
          format: "webp",
        },
        class: "sf-banner--slim text-white-desktop padding-top",
        link: "/b/nike.html?on_sale=1&page=0",
      },
      {
        slot: "banner-B",
        subtitle: app.i18n.t("NEW BRAND"),
        title: app.i18n.t(""),
        description: app.i18n.t(""),
        buttonText: app.i18n.t("Shop now"),
        image: isDesktop
          ? "https://cdn.hudson.com.mt/assets/hudson-store/banners/desktop/Tommy-Hilfiger.webp"
          : "https://cdn.hudson.com.mt/assets/hudson-store/banners/mobile/Tommy-Hilfiger.webp",
        imageConfig: {
          fit: "cover",
          width: isDesktop ? 496 : 328,
          height: isDesktop ? 840 : 343,
          format: "webp",
        },
        class: "sf-banner--slim banner-central text-white-mobile padding-top",
        link: "/b/tommy-hilfiger.html?on_sale=1&page=0",
      },
      {
        slot: "banner-C",
        subtitle: app.i18n.t("NEW BRAND"),
        title: app.i18n.t(""),
        buttonText: app.i18n.t("Shop now"),
        image: isDesktop 
          ? "https://cdn.hudson.com.mt/assets/hudson-store/banners/desktop/Calvin-Klein.webp" 
          : "https://cdn.hudson.com.mt/assets/hudson-store/banners/mobile/Calvin-Klein.webp",
        imageConfig: {
          fit: "cover",
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: "webp",
        },
        class: "sf-banner--slim align-flex-end padding-bottom",
        link: "/b/calvin-klein.html?on_sale=1&page=0",
      },
      {
        slot: "banner-D",
        subtitle: app.i18n.t("HOT BRAND"),
        title: app.i18n.t(""),
        buttonText: app.i18n.t("Shop now"),
        image: isDesktop
          ? "https://cdn.hudson.com.mt/assets/hudson-store/banners/desktop/Armani.webp"
          : "https://cdn.hudson.com.mt/assets/hudson-store/banners/mobile/Armani.webp",
        imageConfig: {
          fit: "cover",
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: "webp",
        },
        class: "sf-banner--slim text-white align-flex-end padding-bottom",
        link: "/b/armani-exchange.html?on_sale=1&page=0",
      },
    ]);

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: "home" }]);
    });
    useMeta({
      title: config.value.default_title,
      script: [
        {
          type: "application/ld+json",
          json: { ...homeJsonLd },
        },
      ],
      meta: [
        {
          hid: "apple-mobile-web-app-title",
          name: "apple-mobile-web-app-title",
          content: config.value.default_title,
        },
        {
          hid: "og:title",
          name: "og:title",
          property: "og:title",
          content: config.value.default_title,
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          property: "og:site_name",
          content: config.value.default_title,
        },
      ],
    });

    const hideNewsModel = async () => {
      showNewsModel.value = false;
    };

    // @ts-ignore
    return {
      banners,
      hero,
      showNewsModel,
      hideNewsModel,
    };
  },
});
